<template>
  <Layout :tituloPagina="'Network | NOCs | ' +(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="row">
      <div class="col-lg-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Edición de'}} NOC
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <label>Descripción del NOC</label>
                <input
                  type="text"
                  ref="descripcion"
                  class="form-control"
                  v-model="noc.descripcion"
                  placeholder="Descripción del NOC"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-12">
                <label>Dirección IP</label>
                <input
                  ref="direccion_ip"
                  type="text"
                  class="form-control"
                  v-model="noc.direccion_ip"
                  placeholder="Direcion IP (192.168.1.0/24) o (192.168.1.0)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-5 text-right">
        <div class="btn-group">
          <button class="btn btn-secondary" @click="atras()">
            <i class="mdi mdi-chevron-left"></i>
            Atrás
          </button>
          <button
            class="btn btn-success"
            @click="modo == 'nuevo' ? guardar() : actualizar()"
            :disabled="bandera_spinner"
          >
            <i 
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            {{modo == 'nuevo' ? 'Guardar' : 'Actualizar'}}
          </button>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import NocSrv from '@/services/NocSrv.js'

var ipaddr = require('ipaddr.js')

export default {
  name: 'EdicionNoc',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      noc: {
        id: null,
        descripcion: '',
        direccion_ip: ''
      },
      bandera_spinner: false
    };
  },

  created: function() {
    var self = this

    // Determinación del modo
    if (this.$route.path.indexOf('network/nocs/nuevo') != -1) this.modo = 'nuevo'
    else this.modo = 'edicion'

    if (this.modo == 'edicion') 
      this.cargarNoc()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      let noc = Object.assign({}, self.noc)
      
      if (!self.datosCorrectos()) return

      NocSrv.actualizar(noc).then(response => {
        iu.msg.success('Se actualizó correctamente el NOC')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el NOC'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras() {
      this.$router.go(-1)
    },

    cargarNoc: function() {
      var self = this,
        idNoc = this.$route.params.id

      NocSrv.nocJSON(idNoc).then(response => {
        self.noc = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    datosCorrectos: function() {
      var self = this,
        noc = this.noc

      if (noc.descripcion == '') {
        iu.msg.warning('Es necesario una descripción.')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return false
      }

      try {
        let ipCidr

        if(noc.direccion_ip.indexOf("/") != -1) ipCidr = noc.direccion_ip
        else ipCidr = noc.direccion_ip+'/32'

        ipaddr.IPv4.parseCIDR(ipCidr)
      } catch (error) {
        iu.msg.warning("La dirección IP está mal escrita, verifique que ingreso correctamente la dirección IP")
        self.$refs.direccion_ip.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      let noc = Object.assign({}, self.noc)

      if (!self.datosCorrectos()) return

      NocSrv.guardar(noc).then(response => {
        iu.msg.success('Se guardó correctamente el NOC')
        self.atras()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el NOC'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    }
  }
}
</script>

<style scoped>

</style>